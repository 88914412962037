window.modalHandler = () => {
  if (!window.modalInitialized) {
    document.addEventListener('DOMContentLoaded', () => {
      showModal();
      closeModal();
      consentEvent();
      submitEvent();
    });
    window.modalInitialized = true;
  }

  const showModal = () => {
    window.addEventListener('click', (e) => {
      const isBtnToShowModal = e.target.classList.contains('jsShowModal');

      if (isBtnToShowModal) {
        const button = e.target;
        const modalName = button.getAttribute('data-modal');
        const modal = document.querySelector(`#${modalName}`);

        modal.classList.add('active');
        document.body.classList.add('block-scroll');
      }
    });
  };

  const closeModal = () => {
    // old?
    window.addEventListener('click', e => {
      const isCloseBtn = e.target.classList.contains('jsMClose');

      if (isCloseBtn) {
        const close = e.target;
        const modal = close.closest(".jsM");

        modal.classList.remove('active');
        setTimeout(function () {
          modal.classList.remove('m-success');
          const errorNode = modal.querySelector('.m-error-text');
          if (errorNode) {
            errorNode.remove();
          }
        }, 300);
        document.body.classList.remove('block-scroll');
      }
    });

    // after refactor and CSS reduce
    window.addEventListener('click', e => {
      const isCloseButton = e.target.classList.contains('jsModalClose');
      const html = document.querySelector('html');

      if (isCloseButton) {
        const closeButton = e.target;
        const modal = closeButton.closest('.jsModal');

        modal.classList.remove('active');
        document.body.style.overflow = null;
        html.style.overflow = null;
      }
    });
  };

  const consentEvent = () => {
    window.addEventListener('click', e => {
      const isConsent = e.target.offsetParent?.classList.contains('jsMConsent');

      if (isConsent) {
        const consent = e.target.offsetParent;
        const modal = consent.closest('.jsM');
        const checkbox = modal.querySelector('.jsMCheckboxConsent');
        const submit = modal.querySelector('.jsMSubmit');

        checkbox.checked
          ? submit.removeAttribute('disabled')
          : submit.setAttribute('disabled', 'disabled');
      }
    });
  };

  const submitEvent = () => {
    window.addEventListener('click', e => {
      const isSubmitBtn = e.target.classList.contains('jsMSubmit');
      const isInsight = e.target.classList.contains('jsMInsight');
      if (isSubmitBtn) {
        e.preventDefault();
        const submit = e.target;
        const modal = submit.closest('.jsM');
        const title = modal.querySelector('.jsMTitle');
        const successTitle = modal.querySelector('.jsMSuccessTitle');
        const description = modal.querySelector('.jsMDescription');
        const successDescription = modal.querySelector('.jsMSuccessDescription');
        const form = modal.querySelector('.jsMForm');
        const fields = form.querySelectorAll('.jsMField');
        const data = {};
        const url = isInsight ?  '/insights/insights-save-popup-form/' : '/solutions/solution-save-popup-form/';
        if (!form.checkValidity()) {
          return;
        }

        fields.forEach(field => {
          const fieldName = field.id;

          data[fieldName] = field.value;
        });

        data['submitted_from'] = location.href;

        $.ajax({
          type: 'POST',
          url,
          data,
          success: (data) => {
            modal.classList.add('m-success');
            form.reset();
          },
          error: (data) => {
            const errorNode = document.createElement('p');
            errorNode.classList.add('m-error-text');
            errorNode.innerText = "There was a problem. Please try again later";
            description.parentNode.insertBefore(errorNode, description.nextSibling);
            console.log('failure ' + data)
          },
          headers: {
            'X-CSRFToken': getCookie('csrftoken')
          },
        });
      }
    });
  };
}
